<nav class="navbar navbar-expand-lg fixed-top navbar-configuration">
    <a class="navbar-brand logo-banner" routerLink="/">
        <img src="../../../assets/logo/logo-principal-banner.jpg" alt="">
        <h2> HIGH QUALITY GUARANTIED </h2>
        <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"> <i class="fas fa-bars"></i></span>
        </button>
    </a>
    <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav ml-auto">
            <li (click)="cerrar()" class="nav-item  active">
                <a class="nav-link " SimpleSmoothScroll [showHash]="true" [offset]="-90" href="#sesion-home">Home <span
                        class="sr-only">(current)</span></a>
            </li>
            <li (click)="cerrar()" class="nav-item  ">
                <a class="nav-link " SimpleSmoothScroll [showHash]="true" [offset]="-90" href="#sesion-about">About
                    us</a>
            </li>
            <li (click)="cerrar()" class="nav-item ">
                <a class="nav-link " SimpleSmoothScroll [showHash]="true" [offset]="-90"
                    href="#sesion-services">Services</a>
            </li>
            <li (click)="cerrar()" class="nav-item  ">
                <a class="nav-link " SimpleSmoothScroll [showHash]="true" [offset]="-90"
                    href="#sesion-portfolio">portfolio</a>
            </li>
            <li (click)="cerrar()" class="nav-item ">
                <a class="nav-link " SimpleSmoothScroll [showHash]="true" [offset]="-70"
                    href="#sesion-contact">Contacts</a>
            </li>
        </ul>
    </div>
</nav>