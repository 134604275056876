import { Component, ViewChild, ElementRef } from '@angular/core';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { SimpleSmoothScrollOption } from 'ng2-simple-smooth-scroll';
import * as AOS from 'aos';



declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-dream-app';

  constructor(
    public smooth: SimpleSmoothScrollService
  ) { }


  ngOnInit() {
    AOS.init();
  }

  gocontact() {
    this.smooth.smoothScrollToTop({ duration: 3000, easing: 'linear' });
  }


}
