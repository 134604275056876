import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Mugan86GoogleAnalyticsModule } from 'mugan86-ng-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageModule } from './pages/page.module';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxGalleryModule,
    SimpleSmoothScrollModule,
    PageModule,
    Mugan86GoogleAnalyticsModule.forRoot(
      {
        analyticsId: '<nuestro-id-de-seguimiento>',
        showLog: true
      }
    )


  ],
  exports: [

    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

