import { Component, OnInit } from '@angular/core';
import { ContactoService } from '../../services/contacto.service';
import { ServicesService } from '../../services/services.service';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.scss']
})
export class ContactosComponent implements OnInit {

  dataContacto = {
    nombre: '',
    email: '',
    club: '',
    tipo: 'Quote',
    mensaje: ''
  };

  constructor(
    private Contanto: ContactoService,
    private services: ServicesService
  ) { }

  ngOnInit(): void {
  }

  enviarContacto(): void {
    if (this.validar()) {
      console.log(this.dataContacto);
      this.Contanto.enviarContacto(this.dataContacto).then(value => value.subscribe(res => {
        // tslint:disable-next-line: no-string-literal
        if (res['status'] === 'success') {
          this.services.Alert('success', '', 'Contact was sent', 'Aceptar', '', false);
          this.limpiar();
        } else {
          this.services.Alert('error', '', 'Cannot send mail', 'Aceptar', '', false);
        }
      }, error => {
        console.log(error);
      }));
    }
  }

  limpiar(): void {
    this.dataContacto = {
      nombre: '',
      email: '',
      club: '',
      tipo: 'Quote',
      mensaje: ''
    };
  }

  validar(): boolean {
    if (!this.services.validarText(this.dataContacto.nombre)) {
      this.services.Alert('error', '', 'Name is empty', 'Aceptar', '', false);
      return false;
    } else if (!this.services.validarNumero(this.dataContacto.club)) {
      this.services.Alert('error', '', 'Phone is empty', 'Aceptar', '', false);
      return false;
    } else if (!this.services.validarCorreo(this.dataContacto.email)) {
      this.services.Alert('error', '', 'The mail is not formatted test@test.com', 'Aceptar', '', false);
      return false;
    } else if (!this.services.validarText(this.dataContacto.tipo)) {
      this.services.Alert('error', '', 'The service is empty', 'Aceptar', '', false);
      return false;
    } else if (!this.services.validarText(this.dataContacto.mensaje)) {
      this.services.Alert('error', '', 'The message is empty', 'Aceptar', '', false);
      return false;
    }
    return true;
  }

}
