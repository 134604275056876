<section id="sesion-services">

    <div class="contenedor-card">

        <div class="row row-home-services">
            <div class="col-12 columns-card-padre">

                <div class="columns-card">
                    <img class="figure-img img-fluid rounded" src="../../../assets/galeria/Exteriors.jpeg" alt="">
                    <h5>Exteriors</h5>
                    <p>Are you building your house and it is time to add the finishing details? We can help you turn a good looking house into the home of your dreams. We have experience in making beautiful houses in stucco, EIFS, stone and much more, so
                        we can achieve exactly what you imagined. Come check out all of the services we can provide to the exterior of your property.
                    </p>
                    <!-- <button class="button-general red-button-color button-quote">
                        Learn More!
                    </button> -->
                </div>
                <div class="columns-card">
                    <img class="figure-img img-fluid rounded" src="../../../assets/galeria/Interiors.jpeg" alt="">
                    <h5>Interiors</h5>
                    <p>It's time to increase the value of your property by enhancing the interiors. You know whether you're selling or buying a house, interior details not only give the best impression, but also increase comfort. The best way to do this
                        is with stucco and when it comes to stucco, we are the best, we can provide you with a variety of services for styling the interior of your house or building that cozy fire place you've always wanted.

                    </p>
                    <!-- <button class="button-general red-button-color button-quote">
                        Learn More!
                    </button> -->
                </div>
                <div class="columns-card">
                    <img class="figure-img img-fluid rounded" src="../../../assets/galeria/Repairs.jpeg" alt="">
                    <h5>Repairs & Remodeling</h5>
                    <p>If your house has suffered from an accident or it's getting to old, only trust a specialist to fix it. Luckily for you, we are, so you don't have to look anywhere else. Only the best can make the damaged look as good as new. Click
                        below to find out the many ways we can repair for you and your home. Free estimates Virgina, Washington DC, Maryland.</p>
                    <!-- <button class="button-general red-button-color button-quote">
                        Learn More!
                    </button> -->
                </div>
                <div class="columns-card">
                    <img class="figure-img img-fluid rounded" src="../../../assets/galeria/stone.jpeg" alt="">
                    <h5>stone</h5>
                    <p>If you are looking to give a striking appearance to the facade of your house, we give you the answer. Ameristucco. We offer our natural stone installation service for cladding and flooring. With an infinity of products to choose from
                        and with the best advice that we can provide you from the beginning of the project to its completion.
                    </p>
                    <!-- <button class="button-general red-button-color button-quote">
                        Learn More!
                    </button> -->
                </div>
            </div>

        </div>
    </div>
    <div class="container-fluid">

        <div class="row row-home-contact">
            <div class="col-12 home-edit-resquest">
                <h5>
                    We Are Ameri Stucco
                </h5>
                <h6>
                    We have been transforming the ideas and visions into award-winning projects.
                </h6>
                <a SimpleSmoothScroll [showHash]="true" [offset]="-70" href="#sesion-contact"> <button class="button-general red-button-color button-quote">
                        <i class="far fa-envelope"></i> Request a quote
                    </button>
                </a>
            </div>
        </div>
    </div>


    <!--

    <div class="contenedor-services">
        <div class="row row-services">
            <div class="col-12">
                <h2 title-services>Services</h2>
            </div>
        </div>
        <div class=" row-img-services d-none d-sm-flex">
            <div class="container-tittle-services">
                <span>
                    <img src="../../../assets/services/interiores.png" alt="">
                    <h4>Interiors</h4>
                </span>
            </div>
            <div class="container-tittle-services">
                <span>
                    <img src="../../../assets/services/exteriores.png" alt="">
                    <h4>Exteriors</h4>
                </span>

            </div>
            <div class="container-tittle-services">
                <span>
                    <img src="../../../assets/services/reparaciones.png" alt="">
                    <h4>Repairs & Remodeling</h4>
                </span>

            </div>
        </div>
        <div class=" row-img-services">

            <div class="container-img-services">
          
                <div class="container-tittle-services d-sm-none">
                    <span>
                        <img src="../../../assets/services/interiores.png" alt="">
                        <h4>Interiors</h4>
                    </span>
                </div>
                <p>With over a decade of experience working on exteriors, we've learnt not only the best technique but
                    also
                    to work only with the best materials. As our first business line, our work on exteriors speaks by
                    itself...</p>
                <button class="button-general red-button-color button-quote" data-toggle="modal"
                    data-target="#exampleModal">
                    Learn More!
                </button>

            </div>
            <div class="container-img-services">
              
                <div class="container-tittle-services d-sm-none">
                    <span>
                        <img src="../../../assets/services/exteriores.png" alt="">
                        <h4>Exteriors</h4>
                    </span>

                </div>
                <p>With over a decade of experience working on exteriors, we've learnt not only the best technique but
                    also
                    to work only with the best materials. As our first business line, our work on exteriors speaks by
                    itself...</p>
                <button class="button-general red-button-color button-quote" data-toggle="modal"
                    data-target="#exampleModal">
                    Learn More!
                </button>
            </div>
            <div class="container-img-services">
             
                <div class="container-tittle-services d-sm-none">
                    <span>
                        <img src="../../../assets/services/reparaciones.png" alt="">
                        <h4>Repairs & Remodeling</h4>
                    </span>

                </div>
                <p>With over a decade of experience working on exteriors, we've learnt not only the best technique but
                    also
                    to work only with the best materials. As our first business line, our work on exteriors speaks by
                    itself...</p>
                <button class="button-general red-button-color button-quote" data-toggle="modal"
                    data-target="#exampleModal">
                    Learn More!
                </button>
            </div>
        </div>

    </div>
    modal leanr more

    -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>

</section>