import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  api = 'https://ameristuccollc.com/api/controllers/';

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  metodoPost(url, body) {
    return this.http.post(this.api + '' + url, body);
  }
}
