import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';



@NgModule({
  declarations: [
    HomeComponent,
    NosotrosComponent,
    GaleriaComponent,
    ContactosComponent,
    ServiciosComponent,
  ],
  exports: [
    HomeComponent,
    NosotrosComponent,
    GaleriaComponent,
    ContactosComponent,
    ServiciosComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxGalleryModule,
    SimpleSmoothScrollModule,
    CarouselModule,
  ]
})
export class PageModule { }
