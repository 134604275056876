<div class="ws">
    <a href="https://wa.me/17038987897" target="_blank"> <img src="../../../assets/oursupport/ws.png" alt=""></a>
</div>


<section id="sesion-home">


    <div class="container-fluid">
        <div class="row row-home">
            <div class="col-12 px-0">
                <owl-carousel-o [options]="customOptions" class="carousel-home-principal">
                    <ng-template class="item" carouselSlide><img src="../../../assets/galeria/1.jpeg" alt="">
                        <h2 data-aos="fade-up" data-aos-delay="400" class="carousel-home-titulo">
                            Choose Pur Passion and professionalism
                        </h2>

                    </ng-template>
                    <ng-template class="item" carouselSlide><img src="../../../assets/galeria/2.jpeg" alt="">
                        <h2 data-aos="fade-up" data-aos-delay="400" class="carousel-home-titulo">
                            WE ARE BRING PROJECTS TO LIFE
                        </h2>
                    </ng-template>
                    <ng-template class="item" carouselSlide><img src="../../../assets/galeria/3.jpeg" alt="">
                        <h2 data-aos="fade-up" data-aos-delay="400" class="carousel-home-titulo">
                            WE ARE BRING PROJECTS TO LIFE
                        </h2>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <div class="row row-home-contact">
            <div class="col-12 home-edit-resquest">
                <h5>
                    We Are Ameri Stucco
                </h5>
                <h6>
                    We have been transforming the ideas and visions into award-winning projects.
                </h6>
                <a SimpleSmoothScroll [showHash]="true" [offset]="-70" href="#sesion-contact"> <button
                        class="button-general red-button-color button-quote">
                        <i class="far fa-envelope"></i> Request a quote
                    </button>
                </a>
            </div>
        </div>
    </div>

</section>