import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor() { }

  // tslint:disable-next-line: typedef
  Alert(icon, title, text, confirmButtonText, cancelButtonText, showCancelButton) {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      cancelButtonText,
      showCancelButton
    });
  }

  // tslint:disable-next-line: typedef
  validarNumero(valor) {
    // tslint:disable-next-line: max-line-length
    if (isNaN(valor) === true || valor === 'NaN' || valor <= 0.000000 || valor === undefined || valor === null || valor === '' || valor === 'null') {
      return false;
    } else {
      return true;
    }
  }

  // tslint:disable-next-line: typedef
  validarText(valor) {
    if (valor === undefined || valor == null || valor === '' || valor === 'null' || valor <= 0) {
      return false;
    } else {
      return true;
    }
  }

  // tslint:disable-next-line: typedef
  validarCorreo(email) {
    // tslint:disable-next-line: max-line-length
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    return emailRegex.test(email);
  }
}
