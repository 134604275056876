<div class="row higadomix2">
    <div class="col-sm-3">
        <img class="img-fluid" src="../../../assets/oursupport/sto.png" alt="">
    </div>
    <div class="col-sm-3">
        <img class="img-fluid" src="../../../assets/oursupport/totalwall.png" alt="">
    </div>
    <div class="col-sm-3">
        <img class="img-fluid" src="../../../assets/oursupport/ParexUSA.png" alt="">
    </div>
    <div class="col-sm-3">
        <img class="img-fluid" src="../../../assets/oursupport/drivit-logo.png" alt="">
    </div>
</div>



<section id="sesion-contact">


    <div class="contenedor-galeria">
        <div class="row row-galeria">
            <div class="col-12">
                <h2 title-galeria>Contacto</h2>
            </div>
        </div>


        <div class="maps-contact" style="width: 100%"><iframe iframe width="100%" height="300" frameborder="0"
                scrolling="no" marginheight="0" marginwidth="0" marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=7701%20Eagle%20ave%20%20Alexandria%20VA%2022306+(Americ%20Stucco%20Contractors%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
                href="https://www.mapsdirections.info/marcar-radio-circulo-mapa/"></a>
        </div>

        <div class="app-formulario">
            <div class="row">
                <div class="col-sm-6">
                    <h2>CONTACT INFORMATION</h2>
                    <div class="d-flex">
                        <i class="fas fa-map-marker-alt"></i>
                        <div class="">

                            <h6>Visit Our Company at</h6>
                            <h6>7701 Eagle Ave, Alexandria, VA</h6>
                        </div>
                    </div>
                    <div class="d-flex">
                        <i class="fas fa-mobile"></i>
                        <div class="">

                            <h6>Have a Questions? Call us</h6>
                            <h6>+1 703-898-7897</h6>
                        </div>
                    </div>
                    <div class="d-flex">
                        <i class="far fa-clock"></i>
                        <div class="">

                            <h6>Our support available to help you2 4 hours a day, six days a week.</h6>
                            <ul>
                                <li>Monday–Saturday: 07:00 am – 7:00 pm</li>
                                <li>Sunday: Close</li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex">
                        <i class="far fa-envelope"></i>
                        <div class="">

                            <h6>Contact with Us</h6>
                            <h6>E-mail: Ameristucco@yahoo.com </h6>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" ng-app="formularios">
                    <h2>FEEDBACK FORM CONTACT WITH US</h2>
                    <p>If you’d like a free consultation, please start by completing the form:</p>

                    <div class="row">
                        <div class="col-sm-6">
                            <label for="">Your Name *</label>
                            <input id="nombre" [(ngModel)]="dataContacto.nombre" class="form-control" type="text">
                        </div>
                        <div class="col-sm-6">
                            <label for="">Your Phone *</label>
                            <input id="phone" [(ngModel)]="dataContacto.club" class="form-control" type="text">
                        </div>
                        <div class="col-sm-6">
                            <label for="">Your E-mail *</label>
                            <input id="email" [(ngModel)]="dataContacto.email" class="form-control" type="text">
                        </div>
                        <div class="col-sm-6">
                            <label for="">Type Service *</label>
                            <select class="form-control" name="" id="" [(ngModel)]="dataContacto.tipo">
                                <option value="Quote">Quote</option>
                                <option value="Exterior">Exterior</option>
                                <option value="Interior">Interior</option>
                                <option value="Repairs & Remodeling">Repairs & Remodeling</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label for="">Your Message *</label>

                            <textarea [(ngModel)]="dataContacto.mensaje" id="message" class="form-control" name="" id=""
                                cols="30" rows="10"></textarea>
                        </div>
                        <div class="col-12 my-3">

                            <button class="button-general red-button-color button-quote" (click)="enviarContacto()">
                                Send
                            </button>
                            <!-- <pre>{{contacto | json}}</pre> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<section id="sesion-footer">

    <!-- Footer -->
    <footer class="page-footer font-small cyan darken-3">

        <!-- Footer Elements -->
        <div class="container">

            <!-- Grid row-->
            <div class="row">

                <!-- Grid column -->
                <div class="col-md-12">
                    <div class=" redes-sociales">
                        <!-- Facebook -->
                        <a class="fb-ic">
                            <i class="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                        </a>
                        <!-- Twitter -->
                        <a class="tw-ic">
                            <i class="fab fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                        </a>
                        <!-- Google +-->
                        <a class="gplus-ic">
                            <i class="fab fa-google-plus-g fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                        </a>
                        <!--Linkedin -->
                        <a class="li-ic">
                            <i class="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                        </a>
                        <!--Instagram-->
                        <a class="ins-ic">
                            <i class="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                        </a>
                        <!--Pinterest-->
                        <a class="pin-ic">
                            <i class="fab fa-pinterest fa-lg white-text fa-2x"> </i>
                        </a>
                    </div>
                </div>
                <!-- Grid column -->

            </div>
            <!-- Grid row-->

        </div>
        <!-- Footer Elements -->

        <!-- Copyright -->
        <div class="footer-copyright text-center py-3">©2020 Ameristucco Constractors LLC. All Rights Reserved.
        </div>
        <!-- Copyright -->

    </footer>
    <!-- Footer -->
</section>