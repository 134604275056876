import { Component, OnInit } from '@angular/core';
declare var $: any;



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(


  ) { }

  ngOnInit(): void {
  }

  cerrar() {
    if ($(".navbar-collapse").hasClass("show") == true) {
      $(".navbar-collapse").removeClass("show");
    } else {
      $(".navbar-collapse").addClass("show");

    }
  }



}
