<section id="sesion-portfolio">

    <div class="contenedor-galeria">
        <div class="row row-galeria">
            <div class="col-12">
                <h2 title-galeria>Portfolio</h2>
            </div>
        </div>


        <div class="row">
            <div class="col-12 altura-max ">
                <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>

            </div>
        </div>

    </div>



</section>