import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(
    private servicio: ServicesService,
    private dataServise: DataService
  ) { }

  // tslint:disable-next-line: typedef
  async enviarContacto(data) {
    const url = 'contacto/prepararEmail.php';
    const res = await this.dataServise.metodoPost(url, data);
    return res;
  }
}
