import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import 'hammerjs';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true
      }
    ];

    this.galleryImages = [
      {
        small: '../../../assets/galeria/imagenes/19.jpeg',
        medium: '../../../assets/galeria/imagenes/19.jpeg',
        big: '../../../assets/galeria/imagenes/19.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/20.jpeg',
        medium: '../../../assets/galeria/imagenes/20.jpeg',
        big: '../../../assets/galeria/imagenes/20.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/21.jpeg',
        medium: '../../../assets/galeria/imagenes/21.jpeg',
        big: '../../../assets/galeria/imagenes/21.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/22.jpeg',
        medium: '../../../assets/galeria/imagenes/22.jpeg',
        big: '../../../assets/galeria/imagenes/22.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/1.jpeg',
        medium: '../../../assets/galeria/imagenes/1.jpeg',
        big: '../../../assets/galeria/imagenes/1.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/2.jpeg',
        medium: '../../../assets/galeria/imagenes/2.jpeg',
        big: '../../../assets/galeria/imagenes/2.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/3.jpeg',
        medium: '../../../assets/galeria/imagenes/3.jpeg',
        big: '../../../assets/galeria/imagenes/3.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/4.jpeg',
        medium: '../../../assets/galeria/imagenes/4.jpeg',
        big: '../../../assets/galeria/imagenes/4.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/5.jpeg',
        medium: '../../../assets/galeria/imagenes/5.jpeg',
        big: '../../../assets/galeria/imagenes/5.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/6.jpeg',
        medium: '../../../assets/galeria/imagenes/6.jpeg',
        big: '../../../assets/galeria/imagenes/6.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/7.jpeg',
        medium: '../../../assets/galeria/imagenes/7.jpeg',
        big: '../../../assets/galeria/imagenes/7.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/8.jpeg',
        medium: '../../../assets/galeria/imagenes/8.jpeg',
        big: '../../../assets/galeria/imagenes/8.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/9.jpeg',
        medium: '../../../assets/galeria/imagenes/9.jpeg',
        big: '../../../assets/galeria/imagenes/9.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/10.jpeg',
        medium: '../../../assets/galeria/imagenes/10.jpeg',
        big: '../../../assets/galeria/imagenes/10.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/11.jpeg',
        medium: '../../../assets/galeria/imagenes/11.jpeg',
        big: '../../../assets/galeria/imagenes/11.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/12.jpeg',
        medium: '../../../assets/galeria/imagenes/12.jpeg',
        big: '../../../assets/galeria/imagenes/12.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/13.jpeg',
        medium: '../../../assets/galeria/imagenes/13.jpeg',
        big: '../../../assets/galeria/imagenes/13.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/15.jpeg',
        medium: '../../../assets/galeria/imagenes/15.jpeg',
        big: '../../../assets/galeria/imagenes/15.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/16.jpeg',
        medium: '../../../assets/galeria/imagenes/16.jpeg',
        big: '../../../assets/galeria/imagenes/16.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/17.jpeg',
        medium: '../../../assets/galeria/imagenes/17.jpeg',
        big: '../../../assets/galeria/imagenes/17.jpeg'
      },
      {
        small: '../../../assets/galeria/imagenes/18.jpeg',
        medium: '../../../assets/galeria/imagenes/18.jpeg',
        big: '../../../assets/galeria/imagenes/18.jpeg'
      }

    ];


  }

}
