<section id="sesion-about">

    <div class="contenedor-about-us">
        <div class="row row-about-us">
            <div class="col-sm-8 ">
                <h2 title-about-us>About us</h2>
                <div class="row">
                    <div class="col-12 content-about-us">
                        <h3>
                            <i class="far fa-star"></i><i class="far fa-star"></i> Years Of Experience

                        </h3>
                        <p>What sets us apart from other stucco companies is our years of experience in handling
                            intricate jobs. We pride ourselves on an outstanding record of quality service and
                            attention to detail. Our longevity in the stucco industry and the longevity of our
                            client relationship complemented our philosophy to treat your home as we would treat
                            ours and the outstanding work we do.</p>
                        <h3>
                            <i class="fas fa-medal"></i> Quality Materials

                        </h3>
                        <p>Our stucco contractors are confident in our expertise and abilities to deliver top-notch
                            quality and artistry using premium quality materials. That is why our stucco contractors
                            provide the best results and achieve the satisfaction of our customers to the utmost degree.
                        </p>
                        <h3>
                            <i class="fas fa-users"></i> Professional Team

                        </h3>
                        <p>Our stucco company has professionals that are dedicated to providing you with the highest
                            level of service. Each of our team brings meaning to every interaction, listens to your
                            needs, and our customers love us for it. Whether you need a stucco installation, stucco
                            restoration need, or stucco refinish project, you can rest assured that our professional
                            team will make the process easy and enjoyable for you.</p>
                        <h3>
                            <i class="fas fa-eye"></i> Exceptional Look

                        </h3>
                        <p>We are passionate about our stucco projects and are performed by our knowledgeable and
                            competent craftsmen. That is why we believe that our works are outstanding in the
                            competition. When you choose to work with us, you’re getting unmatched quality, service, and
                            beautiful results that last a long time.</p>

                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <h3>Our services</h3>
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    COMMERCIAL
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Our commercial stucco offers an incredible selection of services such as stucco
                                    installation, interior stucco and exterior stucco finishing, and stucco repair.
                                    Interior and exterior stucco services are important to create a strong image and
                                    cost-effective manner in your commercial property.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    RESIDENTIAL
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p> We’d love to provide your home with all your stucco needs. Our residential stucco
                                    services cover basic stucco installation, replacement, and repair. We also provide
                                    specialized services such as construction installation, including home additions or
                                    remodeling, renovation, and restoration of stucco around your home. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                    REPAIRS
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Our repairs in stucco include cracks repair and patch repair. Cracks and patches are
                                    effects of seasonal weather patterns or gradual shifting of your building. Our
                                    stucco repair service offers a quick solution to restore your stucco to its
                                    excellent condition. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>